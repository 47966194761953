@import '../partials';

.footer {

  &-top {
    background-color: $blue;
    color: $white;
    font-size: rem(14);

    @include bp(md){
      padding: 0 rem(20);
    }

    &__boundary {
      @include content-contaner;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: rem(35);

      @include bp(md){
        padding: rem(45) 0 rem(30) 0;
        flex-direction: row;
      }
    }

    &__info {

    }

    &__logo {
      margin-bottom: rem(20);
    }

    &__links {

    }
  }

  &__menu {
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: rem(20);

    @include bp(md){
      margin-top: 0;
      flex-direction: row;
    }

    &-item {
      list-style: none;
      margin-top: rem(20);

      @include bp(md){
        margin-left: rem(50);
        margin-top: 0;
      }
    }

    &-link {
      color: $white;
      font-weight: $bold;
      letter-spacing: rem(0.6);
      text-transform: uppercase;
      font-size: rem(14);
      transition: color $fast-duration ease;

      &:hover {
        color: $orange;
      }
    }
  }

  &__utility {

    &-links {
      display: flex;
    }

    &-link {
      list-style: none;
      margin-top: rem(20);
      margin-right: rem(30);

      @include bp(md){
        margin-left: rem(30);
        margin-right: 0;
        margin-top: 0;
      }

      a {
        color: $blue;
        text-decoration: underline;
      }
    }
  }

  &-bottom {
    padding: 0 rem(10);
    background-color: $white;
    color: $blue;
    font-size: rem(12);

    @include bp(md){
      padding: 0 rem(20);
    }

    &__boundary {
      @include content-contaner;
      padding: rem(20) 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include bp(md){
        flex-direction: row;
      }
    }
  }



  &__social {
    padding-top: rem(30);

    &-links {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      &__link {
        a {
          font-size: rem(25);
          color: $white;
          margin: 0 rem(40) 0 0;
          transition: color $fast-duration ease;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }
}